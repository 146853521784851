.react-colorful__saturation {
  border-radius: 0 !important;
}

.color-picker-container {
  transition: all 0.3s ease;
  cursor: pointer;
}
  
.color-picker-container.collapsed {
  width: 40px;
  height: 40px;
  overflow: hidden;
}
  
.color-picker-container.expanded {
  width: 240px;
  height: 240px;
}