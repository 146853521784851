.smart-spend-filter-container {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.smart-spend-filter-container>div {
  margin: 0px 5px;
}

div#view-by{
  width: 500px !important; 
  margin-left: auto !important;
}

.brand-names, .year-names, .header-names{
  font-size: 1rem;
  font-weight: bolder;
  padding: 5px;
  margin: auto;
  align-items: center;
  display: flex;
  width: 100%;
  cursor: pointer;
}

.brand-names:hover{
  background-color: #39a3f4;
  color: #ffffff;
  border-radius: 5px;
}

.header-names{
  justify-content: center;
  cursor: auto;
}

.year-names{
  font-weight: normal;
}

.brands-grouping-div {
  height: 100%;
  box-sizing: border-box;
  padding: 0.857em;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}

.brands-name-header{
  display: flex;
  width: 100%;
}