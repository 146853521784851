#crm-source-view {
}

#crm-source-view .fileContainer {
    border-radius: 4px;
    border-bottom: 1px solid #c8c8c8;
}

.crm-source-container {
    position: relative;
    height: 220px;
    padding: 10px;
    cursor: pointer;
}

.crm-img-modal {
    border: 2px solid #c8c8c8;
    border-radius: 4px;
    width: 400px;
    height: 200px;
    display: none;
}

.crm-img-modal .close {
    position: absolute;
    top: -8px;
    right: -8px;
}
