.active {

}

.inactive {
    opacity: 0.5;
    background-color: black;
}

.image-container {
    cursor: pointer;
}