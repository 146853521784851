/*Templates*/
#templates-view {
}

/*Templates Form*/
#templates-form-view {
  height: 100%;
}

#templates-form-view .label-container {
  margin-top: 5px;
}

#templates-form-view .label-container .red {
  color: red;
}

#templates-form-view .p-dropdown-items-wrapper {
  max-height: 400px !important;
}

#template-form {
  width: calc(100% - 495px);
  margin-right: 15px;
}

#template-preview {
  margin-left: auto;
  margin-right: auto;
  width: min-content;
  min-width: 450px;
  height: min-content;
  background-color: #ffffff;
  border-radius: 45px;
  position: relative;
}

@media only screen and (max-width: 900px) {
  #template-form {
    width: 100%;
  }
}

.p-dropdown-filter-container {
  width: 100%;
}

:root {
  --black: #000000;
  --white: #ffffff;
  --error: #e13d3d;
  --light-bg: #f9f9f9;
  --light-grey-border: #e8e8e8;
  --light-grey-border-terms: #eaeaec;
  --light-grey-border-terms-hover: #c7c7cc;

  --border-radius: 16px;
  --primary: #2fc072;
  --primary-hover: #1ea95f;
}

.pureinfluencer-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  font-family: "Satoshi", sans-serif;
  font-size: 16px;
  overflow: auto;
}

.pureinfluencer-popup-body {
  display: flex;
  flex-direction: column;
  max-width: 810px;
  width: 100%;
  position: relative;
  max-height: 100%;
}

#form-body-2 {
  display: block;
}

.pureinfluencer-popup-top {
  flex-shrink: 1;
  background-color: var(--black);
  width: 100%;
  height: 520px;
  min-height: 150px;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.pureinfluencer-popup-close {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
  transition: transform ease 0.2s;
}

.pureinfluencer-popup-close:hover {
  transform: scale(0.9);
}

.pureinfluencer-popup-bottom {
  background-color: var(--white);
  padding: 25px;
  /* border: 1px solid black; */
  margin-top: 15px;
}

.pureinfluencer-popup-inputs {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 32px;
  font-size: 16px;
}

.pureinfluencer-popup input {
  display: block;
  width: 100%;
  background-color: var(--light-bg);
  border: 1px solid var(--light-grey-border);
  border-radius: var(--border-radius);
  padding: 15px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-weight: 400;
  transition: all ease 0.2s;
}

.pureinfluencer-popup input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.pureinfluencer-popup input:hover {
  border-color: var(--light-grey-border-terms-hover);
}

.pureinfluencer-popup input:hover {
  border-color: var(--light-grey-border-terms-hover);
}

.pureinfluencer-popup input:active,
.pureinfluencer-popup input:focus {
  outline: none;
  outline-offset: 0;
  border-color: var(--primary);
}
.pureinfluencer-popup input:user-invalid {
  border-color: var(--error);
}
.pureinfluencer-popup-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  font-size: 18px;
  font-weight: 500;
  height: 52px;
  width: 100%;
  border-radius: var(--border-radius);
  border: none;
  outline-offset: 0;
  outline: none;
  color: var(--white);
  cursor: pointer;
  transition: all ease 0.2s;
}

.pureinfluencer-popup-btn:hover {
  background-color: var(--primary-hover);
}
.pureinfluencer-popup-terms {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  font-size: 16px;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
}

.pureinfluencer-popup-terms input[type="checkbox"] {
  display: none;
  cursor: pointer;
  flex-shrink: 0;
}

.pureinfluencer-popup-terms label {
  position: relative;
  padding-left: 34px;
}

.pureinfluencer-popup-terms label::after {
  content: "";
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid var(--light-grey-border-terms);
  left: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  transition: all ease 0.2s;
}

.pureinfluencer-popup-terms label::before {
  content: "";
  /* background: url(./check-green.svg) center center no-repeat; */
  background-size: contain;
  display: flex;
  width: 13px;
  height: 13px;
  left: 4px;
  cursor: pointer;
  position: absolute;
  top: 4.5px;
  transform: scale(0);
  transition: all ease 0.2s;
  z-index: 1;
}

.pureinfluencer-popup-terms input[type="checkbox"]:checked + label::before {
  transform: scale(1);
}

.pureinfluencer-popup-terms label:hover::after {
  border-color: var(--light-grey-border-terms-hover);
}

.pureinfluencer-popup-terms a {
  color: var(--primary);
  font-weight: 500;
  text-decoration: none;
}
.pureinfluencer-popup-terms a:hover {
  text-decoration: underline;
}

.pureinfluencer-popup-nav {
  display: flex;
  margin-bottom: 32px;
  border-bottom: 1px solid var(--light-grey-border);
}

.pureinfluencer-popup-nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.333%;
  font-size: 20px;
  padding: 12px 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  position: relative;
}

.pureinfluencer-popup-nav-item.active {
  color: var(--primary);
  position: relative;
  font-weight: 500;
}

.pureinfluencer-popup-nav-item::after {
  content: "";
  height: 2px;
  /* background: var(--primary); */
  transform: scaleX(0);
  transform-origin: center;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  transition: all ease 0.2s;
}

.pureinfluencer-popup-nav-item.active::after {
  transform: scaleX(1);
}

.pureinfluencer-popup-search {
  position: relative;
  margin-bottom: 32px;
}
.pureinfluencer-popup-search svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}
.pureinfluencer-popup-search input {
  width: 100%;
  padding-left: 52px;
  min-height: 45px;
}

@media screen and (max-width: 560px) {
  .pureinfluencer-popup-inputs {
    grid-template-columns: 1fr;
  }
  .pureinfluencer-popup-top {
    height: 200px;
  }
  .pureinfluencer-popup-bottom {
    padding: 20px;
  }
  .pureinfluencer-popup-close {
    right: 20px;
    top: 20px;
  }
}

div.single-cta-buttons {
  padding: 0 1.5rem !important;
}

body .p-dropdown-panel .p-dropdown-filter-container .pi {
  top: 25px;
}
