#login-view {
    height: 100%;
    background-color: rgb(61, 88, 153);
}

#login-view #wrapper {
    text-align: center;
    padding-top: 20px;
}

#login-view #wrapper #form {
    width: 350px;
    border-radius: 20px;
    margin: 50px auto auto;
    background-color: white;
}
