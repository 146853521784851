.radio-filter-container {
  flex-wrap: wrap;
}

.radio-filter-container, .radio-filter-item {
  align-items: center;
  display: flex;
  justify-content: center;
}

.radio-filter-item {
  margin: 0px 5px;
  min-width: 60px;
}

.radio-filter-item-label {
  margin: 0px 5px;
}

.email-radio-style {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
.email-radio-item {
  display: flex;
  flex-direction: row-reverse;
}

.autoacquire-type-radio .radio-filter-container {
  flex-direction: column;
  align-items: baseline;
  row-gap: 10px;
  margin-top: 20px;
}

.autoacquire-type-radio .radio-filter-container .radio-filter-item .radio-filter-item-label{
  min-width: 110px;
}