.ioffer-certificate-header {
  height: 150px;
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.ioffer-certificate-footer {
  margin: 30px;
  height: 150px;
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.next-steps-div {
  height: 100px;
  text-align: start;
  font-size: 3rem;
  font-weight: bold;
  color: white;
  padding: 20px;
  background: #7fbd40;
  margin-bottom: 5px;
}

.vertical-line {
  width: 1px;
  height: 250px;
  background-color: black;
  margin: 0 20px;
}

.dealership-info-div {
  min-height: 70px;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  padding: 20px;
  background: #7fbd40;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.vehicle-info-div,
.vehicle-offer-div {
  width: 100%;
  color: black;
  background: white;
}

.vehicle-info-and-offer {
  min-height: 250px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 5px;
}

.vehicle-condition-div p,
.vehicle-description-div p,
.vehicle-offer-div p {
  margin: 0;
}

.vehicle-condition-div,
.vehicle-description-div,
.vehicle-offer-div {
  padding: 10px 30px;
}

@media (max-width: 582px) {
  .vehicle-condition-div,
  .vehicle-description-div,
  .vehicle-offer-div {
    padding: 10px;
  }
  .vertical-line {
    margin: 10px 10px;
  }
}

.vehicle-offer-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
}

.vehicle-condition-div,
.vehicle-description-div {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  font-weight: 500;
}

.offer-steps-div {
  min-height: 300px;
  background: #f0f0f0;
  display: flex;
}

.steps-div,
.steps-div-middle {
  width: 100%;
  display: flex;
  position: relative;
}

@media (max-width: 650px) {
  .steps-div {
    flex-grow: 1;
  }
  .steps-div-middle {
    flex-grow: 2;
  }
  .steps-div,
  .steps-div-middle {
    width: unset;
  }
}

.steps-div-middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 30px;
}

.green-rect-completed {
  width: 25px;
  height: 25px;
  background: #7fbd40;
}

.green-rect {
  width: 25px;
  height: 25px;
  border: 3px solid #7fbd40;
  box-shadow: 0 2px 4px 0px #a7db87;
}

.napoleon-buttons {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.vertical-button-line {
  width: 1px;
  height: 70px;
  background-color: #c0c0c0;
  margin: 0 20px;
}

.middle-div-text {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 10%;
}

.middle-div-text p {
  margin: 0;
}

.middle-div-text-completed {
  height: 70px;
  justify-content: center !important;
}

@keyframes colorChange {
  0% {
    background-color: #7fbd40;
  }
  25% {
    background-color: #73b531;
  }
  50% {
    background-color: #559f0b;
  }
  75% {
    background-color: #66af1e;
  }
  100% {
    background-color: #91d44e;
  }
}

.start-inspection-btn {
  animation: colorChange 4s infinite;
}
