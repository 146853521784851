#accounts-and-users {
}

#accounts-and-users .p-fileupload {
    height: 100%;
}

#accounts-and-users .p-fileupload-content {
    min-height: 110px;
    max-height: calc(100% - 60px);
    overflow-y: auto;
}
