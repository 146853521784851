.report-panel {
    height: auto;
    margin-top: 5px;
}

.doc-tablewrapper {
    overflow: auto;
    text-align: left;
}

.doc-table {
    border-collapse: collapse;
    width: 100%;
    background-color: #ffffff;
}

.doc-table tbody td {
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
}

.doc-table thead th {
    padding: 0 1rem;
}

.chart-container {
    margin: auto;
    max-height: 100px;
    max-width: 60px;
}
#reports-view .p-multiselect-label{
    padding-top: 8px;
    padding-left: 8px;
}