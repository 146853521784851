#subs-view {
    height: 100%;
    background-color: rgb(61, 88, 153);
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.5); 
}

#subs-view #wrapper {
    text-align: center;
    padding-top: 20px;
}

#subs-view #wrapper #form {
    width: 350px;
    border-radius: 20px;
    margin: 50px auto auto;
    background-color: white;
}

.p-button.p-component .p-button-label {
    padding: 5px !important;
}
