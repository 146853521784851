#user-notifications .product-grid-item {
  text-align: left;
}

#user-notifications .p-paginator-icon {
  color: black;
}

#user-notifications .p-sidebar-right {
  top: 50px;
}

#user-notifications .p-sidebar.p-component {
  padding: 0;
  /*background-color: #bfbfbf !important;*/
}

#user-notifications .badge {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #39A3F4;
  border-radius: 20px;
  right: 0;
  top: calc(50% - 4px);
}

#user-notifications .action {
  float: right !important;
  margin-top: -10px !important;
  color: gray !important;
}

#user-notifications .pi-check.p-button-icon-left {
  color: black;
}

#user-notifications .notification-container {
  cursor: pointer;
  margin-bottom: 5px;
}

#user-notifications .notification-container .product-grid-item:hover {
  background-color: #F0F0F0;
}

#user-notifications .notification-container .notification-button:active {
  background-color: #F0F0F0;
  transform: translateY(4px);
}

#user-notifications .notification-button {
  cursor: pointer;
}

#user-notifications .notification-button:active {
  transform: translateY(2px);
}

#user-notifications .p-overlaypanel-content {
  height: 100%;
  padding: 0;
}

#user-notifications #overlay-panel.p-overlaypanel:before {
  left: calc(100% - 25px);
}

#user-notifications #overlay-panel.p-overlaypanel:after {
  left: calc(100% - 25px);
}