
.asset-container {
    cursor: pointer;
}

.asset-container:hover {
    background-color: #dedada;
}

#chat_greetings_modal .p-dataview .p-dataview-content {
    border: none;
}

#chat_greetings_modal #dynamic-offer-container {
    height: 250px;
    border-radius: 10px;
    position: relative;
}

#chat_greetings_modal #dynamic-offer-container #text-wrapper {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 15px;
}

#chat_greetings_modal #dynamic-offer-container .text-container {
    text-align: center;
}

#chat_greetings_modal #dynamic-offer-container .text {
    color: #FFFFFF;
    font-size: 22px;
    font-weight: bold;
}

#chat_greetings_modal #input-wrapper {
    margin-top: 10px;

}

#chat_greetings_modal .input-container {
    width: 100%;
    height: 52px;
    margin-bottom: 5px;
}

#chat_greetings_modal .input-container .input-field {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

#chat_greetings_modal .input-container .p-colorpicker.p-component.p-colorpicker-overlay {
    width: 100%;
}

#chat_greetings_modal .input-container .p-colorpicker.p-component.p-colorpicker-overlay input {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}