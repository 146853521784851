.choose-triggers-main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.choose-triggers-sources {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  align-items: flex-start;
}
.choose-triggers-behaviors {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  align-items: flex-start;
  height: 350px;
  padding: 0;
}
.choose-triggers-mini-container {
  display: flex;
  flex-direction: row;
}
.offer-img {
  width: 250px;
  height: auto;
}
.choose-triggers-mini-container-sources {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 15px;
  margin-top: 20px;
  gap: 20px;
  padding-right: 10px;
}
.choose-triggers-mini-container-behaviors {
  margin-top: 20px;
}
.choose-triggers-label {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 15px !important;
}
.choose-triggers-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.choose-triggers-summary-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-y: hidden;
}
@media (max-width: 750px) {
  .choose-triggers-section {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .choose-triggers-behaviors {
    width: 100%;
  }
}
  /* @media only screen and (max-width: 900px) {
  #my-campaigns-form {
    width: 100%;
  }

  #my-campaigns-preview {
    margin-top: 10px;
  }
} */
