#dealerships_panel .p-datatable {
  height: 100%;
  border: 1px solid #d8d8d8;
}

#dealerships_panel .p-datatable-scrollable-wrapper {
  height: calc(100% - 90px);
}

#dealerships_panel .p-datatable-scrollable-header {
  border: none;
}

#dealerships_panel .p-paginator.p-paginator-bottom {
  border: none;
  padding: 7px;
  border-radius: 0;
}

#dealerships_panel .p-datatable-scrollable-footer {
  border: none;
}

@media screen and (max-width: 40em) {
  .p-datatable-responsive .p-datatable-tbody > tr.p-datatable-row > td {
    text-align: left;
    display: block;
    border: 0 none;
    width: max-content !important;
    float: left;
    clear: left;
  }
}