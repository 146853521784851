#dashboard-view {
}

.dashboard-filter-title {
    font-size: 12px;
    color: gray;
}

#dashboard-view .p-calendar {
    width: 100%;
}

.lead-details-value {
    float: right;
    margin-left: 5px;
}