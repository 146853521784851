.sem-vendor-count-cell-extension {
  margin: 0px 5px;
  opacity: 0.75;
}

.sem-vendor-breakdown-count {
  margin: 0px 5px;
}

.sem-vendor-breakdown-count:hover {
  text-decoration: underline;
}
