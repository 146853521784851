#assets-view {
}

.p-assets-delete-button {
    position: absolute;
    right: -5px;
    top: -5px;
    background-color: white !important;
    color: red !important;
    border-radius: 20px !important;
    border: 1px solid #c8c8c8 !important;
}

#assets-view .fileContainer {
    border-radius: 4px;
    border-bottom: 1px solid #c8c8c8;
}

#assets-view .uploadPicturesWrapper {
    max-height: 500px;
    overflow-y: auto;
}

.p-asset-grid {
    padding: 10px;
    margin: 0;
    border-radius: 2px;
}

.product-grid-item-bottom {
    text-align: center;
}

#assets-view .uploadPictureContainer img {
    width: 100% !important;
    height: 100% !important;
}