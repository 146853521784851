.role-permissions-container {

}

.role-permission-row {
    padding: 10px;
}

.role-permission-row .title {
    margin-left: 3px;
}

.role-category {
    padding: 10px;
}

.role-category .category-container {
    border: 1px solid #dddddd;
}

.role-category .category-row {
    padding: 8px;
    border-bottom: 1px solid #dddddd;
}

.category-row .role-checkbox {
    margin-right: 4px;
}

.p-role-edit-name-button {
    margin-left: 5px !important;
    background-color: transparent !important;
    border: none !important;
    color: black !important;
}

.p-role-edit-name-button.p-button:enabled:focus {
    box-shadow: none !important;
}
