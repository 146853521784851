.sidebar-item {
    width: 100%;
    margin-bottom: 5px;
}

#dealership-campaign .icon {
    float: unset !important;
    font-size: 14px !important;
}

.css-2b097c-container {
    width: 100%;
}

#report-modal .p-dropdown-panel {
    bottom: 34px;
    top: unset !important;
}

#period-chatbots-sidebar .p-inputtext {
    width: 100%;
}

.chatbot-stats-value {
    font-weight: bold;
    margin-left: 5px;
    float: right;
}

.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 130px;
    height: 130px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@media print {
    .p-tabview-nav-container { /* Hide all elements within the body */
        display: none;
    }

    .layout-breadcrumb { /* Hide all elements within the body */
        display: none;
    }
}