#support-portal-view .card {
    padding: 0;
}

.support-portal-panel-left {
    border: none !important;
}

.support-portal-panel-left-button {
    width: 100%;
    text-align: left;
    margin-bottom: 3px;
}


.p-button.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-button.p-button-raised:hover {
    background-color: snow !important;
}

.p-button.p-button-text {
    background-color: transparent;
    color: #2196F3;
    border-color: transparent;
}

.summary-value {
    font-size: 24px;
    font-weight: bold;
}

.summary-text {
    display: block;
    color: #696974;
}

.sidebar-item {
    margin-top: 5px;
}

.status-deactivate {
    padding: 5px;
    color: white;
    background-color: #EF5350;
    border-radius: 4px;
    min-width: 95px;
    text-align: center;
}

.status-running {
    padding: 5px;
    color: white;
    background-color: #6ebc3b;
    border-radius: 4px;
    min-width: 95px;
    text-align: center;
}

.expiry {
    padding: 5px;
    color: white;
    background-color: #f0ad4e;
    border-radius: 4px;
    min-width: 95px;
    text-align: center;
}

.non-expiry {
    padding: 5px;
    color: white;
    background-color: #5bc0de;
    border-radius: 4px;
    min-width: 95px;
    text-align: center;
}
.asset-container{
    cursor: pointer;
}