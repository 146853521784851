#ip_blocking {
    padding: 50px;
    text-align: center;
}

#ip_blocking #container {
    width: 500px;
    height: 300px;
    margin: auto;
}

#ip_blocking #img-container {
    width: 100%;
    height: 100%;
}

#ip_blocking #img-container img {
    width: 100%;
    height: 100%;
}

#ip_blocking #button-container {
    width: 100%;
    display: flex;
    margin-top: 20px;
}