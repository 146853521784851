#global-spinner-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: white;
    opacity: 0.4;
    z-index: 2147483647;
}

#global-spinner {
    position: fixed;
    left: calc(50% - 30px);
    background-color: #FFFFFF;
    border-radius: 180px;
    padding: 8px;
    border: 2px solid rgb(226 226 234);
    width: 60px;
    height: 60px;
    z-index: 2147483647;
}
