.select-actions-main-container {
  height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.select-actions-offers {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  align-items: center;
}
.select-actions-greetings {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  align-items: center;
}
.select-actions-notifications {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.select-actions-mini-container {
  display: flex;
  flex-direction: row;
}
.select-actions-mini-container-offers {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  align-items: center;
  gap: 20px;
  padding-right: 10px;
}
.select-actions-mini-container-greetings {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  align-items: center;
  gap: 20px;
  padding-right: 10px;
}
.select-actions-mini-container-notifications {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  align-items: center;
  gap: 5px;
  padding-right: 5px;
}
.select-actions-label {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 15px;
}
.select-actions-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.select-actions-load-container {
  overflow-y: scroll;
  height: 400px;
}
@media (max-width: 700px) {
  .select-actions-main-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .select-actions-offers {
    overflow-y: auto;
  }
  .select-actions-greetings {
    overflow-y: auto;
  }
  .select-actions-notifications {
    overflow-y: auto;
  }
}
/* @media only screen and (max-width: 900px) {
  #my-campaigns-form {
    width: 100%;
  }

  #my-campaigns-preview {
    margin-top: 10px;
  }
} */
