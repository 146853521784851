.selectable_image_container {
    position: relative;
    max-height: 200px;
    height: 100%;
    width: 100%;
}

.selectable_image_container .selectable_image {
    border: 1px solid #cccccc;
    border-radius: 2px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding: 2px;
}

.selectable_image_container .selectable_image_picker {
    display: none;
}

.selectable_image_container > .edit_image_icon {
    background: white;
    border-radius: 2px;
    bottom: 8px;
    opacity: 0.7;
    padding: 3px;
    pointer-events: none;
    position: absolute;
    right: 4px;
    visibility: hidden;
}

.selectable_image_container:hover > .edit_image_icon {
    visibility: visible;
}