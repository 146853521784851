body .p-datatable .p-datatable-thead > tr > th {
    padding: 0.429em 0.857em;
    border: 1px solid #d8d8d8;
    background-color: #f5f5f5;
    text-align: center;
    width: 100px !important;
}

.printable-disclosure-div {
    display: none;
}

@media print {
    .printable-disclosure-div { /* Hide all elements within the body */
        display: block;
        margin-top: 50px;
        border: 1px solid black;
        padding: 10px;
    }

    .main-buttons {
        display: none;
    }
    @page {
        margin: 0;
    }
}