.smart-spend-data-view-container {
  padding: 2px;
}

.smart-spend-data-view-charts-container>* {
  margin: 10px 0px;
}

.smart-spend-data-view-spinner {
  height: 60px !important;
  left: 50%;
  transform: translateX(-50%);
  width: 60px !important;
}