#visualizer-view .sidebar-controls {
    margin: 10px -15px 15px;
    padding-bottom: 5px;
}

#visualizer-view .sidebar-img {
    float: left;
    width: 18px;
}

#visualizer-view .sidebar-text {
    margin-left: 15px;
    float: left;
    word-wrap: break-word;
    width: calc(100% - 30px);
}

#visualizer-view .row {
    display: flex;
}

.visitor-history {
    padding: 0 !important;
}

.p-overlaypanel:after {
    left: unset;
    right: 2px;
}

.p-overlaypanel:before {
    left: unset;
    right: 0;
}

.legend-selected-visitor {
    background-color: #3DD598;
    border: 2px dashed #FC5A5A;
    box-shadow: 0 0 0 0 #fff, 0 4px 10px 0 #aaa;
}

.legend-organic-direct {
    background-color: #3DD598;
    box-shadow: 0 0 0 3px #fff, 0 4px 10px 0 #aaa;
}

.legend-organic-direct-paid {
    background-color: #3DD598;
    border: 2px solid #FC5A5A;
    box-shadow: 0 0 0 0 #fff, 0 4px 10px 0 #aaa;
}

.legend-previous-engagement-not-converted {
    background-color: #50b5ff;
    box-shadow: 0 0 0 3px #fff, 0 4px 10px 0 #aaa;
}

.legend-paid-visitor {
    background-color: #FC5A5A;
    border: 2px solid #fafaf5;
    box-shadow: 0 0 0 0 #fff, 0 4px 10px 0 #aaa;
}

.legend-converted-via-my-traffic {
    background-color: #50b5ff;
    box-shadow: 0 0 0 3px #fff, 0 4px 10px 0 #aaa;
}

.legend-paid-search-visitor {
    background-color: #FC5A5A;
    border: 2px solid #3DD598;
    box-shadow: 0 0 0 0 #fff, 0 4px 10px 0 #aaa;
}

.legend-converted-natively {
    background-color: #50b5ff;
    box-shadow: 0 0 0 3px #fff, 0 4px 10px 0 #aaa;
}

.legend-restricted-visitor {
    background-color: #ff9100;
    box-shadow: 0 0 0 3px #fff, 0 4px 10px 0 #aaa;
}

.legend-orphan-visitor {
    background-color: #50b5ff;
    box-shadow: 0 0 0 3px #fff, 0 4px 10px 0 #aaa;
}

.legend-item {
    padding: 5px;
    width: 130px;
}

.legend-bubble {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    color: black;
}

.legend-item .description {
    width: 100%;
    font-size: 12px;
}

.legend-item .description p {
    margin-top: 2px;
    margin-bottom: 2px
}


.legend-bubble .bubble-text {
    margin: 0;
    position: absolute;
    top: calc(50% - 13px);
    left: calc(50% - 5px);
    font-size: 18px;
}