#real-time-stats {
  margin-bottom: 5px;
  border-top: 1px solid #F1F1F5;
}

#real-time-stats .title {
  color: #171725;
  font-size: 16px;
  font-weight: bold;
}

#real-time-stats .p-dropdown-filter-container {
  width: 100%;
}

#real-time-stats .value {
  color: #171725;
  font-size: 18px;
  font-weight: bold;
}

.CircularProgressbar-text {
  font-weight: bold;
  margin-top: 10px;
  dominant-baseline: central !important;
}

.text {
  color: #696974;
  font-size: 12px;
  font-weight: bold;
}

.lead {
  color: black;
  font-size: 12px;
  font-weight: bold;
}

.visit {
  color: black;
  font-size: 12px;
  font-weight: bold;
}

#real-time-stats #stats {
  border-right: 1px solid #F1F1F5;
}

#real-time-stats #today {
  border-right: 1px solid #F1F1F5;
}

#real-time-stats #period {
  border-right: 1px solid #F1F1F5;
}

.chart-container {
  width: 33%;
}

#visit-history .p-tabview-panel {
  padding: 0 !important;
}

@media only screen and (max-width: 991px) {
  #stats .content {
    text-align: center;
  }
}

#real-time-stats .chart-container {
  width: 50px;
  margin: auto;
}

#stats {
  border-bottom: 1px solid #F1F1F5;
}

#today {
  border-bottom: 1px solid #F1F1F5;
}

#period {
  border-bottom: 1px solid #F1F1F5;
}

#visualizer-view #menu {
  height: 100%;
  padding: 5px;
}

#visualizer-view #menu .button {
  width: 100%;
  float: left;
  margin-bottom: 2px;
}

#visualizer-view #menu #buttons {
  float: right;
}

#visualizer-view #menu .info {
  color: #0062ff;
  margin-right: -8px;
}

#visualizer-view #menu .icon {
  cursor: pointer;
  float: right;
  border-radius: 45px;
  padding: 2px;
  margin-left: 3px;
  font-size: 25px;
}

#visualizer-view #menu .icon:hover {
  background-color: #F1F1F5;
}

.segment-name {
  font-weight: bold;
  margin-right: 5px;
}

.segment-label {
  font-weight: bold;
  color: gray;
}

.conv-history-label {
  float: right;
}

.conv-history-item {
  border-bottom: 1px solid #F1F1F5;
  padding-top: 2px;
  padding-bottom: 2px;
}

.aes-container {
  height: 30px;
  width: 80px;
}

.aes-container:hover + .aes-button, .aes-button:hover {
  display: block !important;
}

.aes-button {
  display: none !important;
}

.chart-wrapper {
  padding-bottom: 2px !important;
  padding-top: 2px !important;
}