body {
  background-color: #f4f4f4;
}

.p-panel {
  border-radius: 4px;
}

.p-datatable-wrapper {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.p-datatable-resizable > .p-datatable-wrapper {
  overflow-x: auto !important;
}

div.acquisition-grid
  div.p-datatable.p-component.p-datatable-auto-layout.p-datatable-resizable.p-datatable-responsive-stack
  div.p-datatable-wrapper {
  overflow-x: hidden !important;
}

.p-paginator-bottom {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

#main-view {
  height: 100%;
}

.warning-text {
  font-size: 12px;
  color: #ef5350;
}

.react-confirm-alert-button-group {
  width: min-content;
  margin-left: auto;
}

.confirm-save-btn {
  background-color: #39a3f4 !important;
}

.confirm-save-btn:active {
  background-color: #39a3f4 !important;
}

.confirm-discard-btn {
  background-color: #ef5350 !important;
}

.confirm-discard-btn:active {
  background-color: #ef5350 !important;
}

.confirm-cancel-btn {
  color: black !important;
  background-color: #d9d9d9 !important;
}

.confirm-cancel-btn:active {
  background-color: #b9b9b9 !important;
}

.p-submenu-list {
  z-index: 9999999 !important;
}

.p-sidebar {
  background-color: white !important;
  z-index: 2147483647 !important;
}

.panel-title {
  color: gray;
  font-size: 1.3rem;
  font-weight: bold;
}

.Toastify__toast-container {
  z-index: 2147483647 !important;
}

.grid-header {
  display: flex;
  margin-bottom: 10px;
}

.react-confirm-alert-overlay {
  z-index: 2147483647 !important;
}

.p-component-overlay.p-sidebar-mask {
  z-index: 2000 !important;
}

.p-dropdown {
  height: 34px;
}

.css-1pahdxg-control:hover {
  border-color: #39a3f4 !important;
}

.css-1pahdxg-control {
  box-shadow: none !important;
  border-radius: 2px !important;
}

.css-1pahdxg-control {
  height: 34px;
  max-height: 34px;
  min-height: 34px !important;
}

.multi-select .css-1pahdxg-control {
  height: auto;
  max-height: unset;
}

.css-26l3qy-menu {
  text-align: left;
}

.css-9gakcf-option {
  background-color: #39a3f4 !important;
}

.css-yk16xz-control {
  height: 34px;
  max-height: 34px;
  min-height: 34px !important;
}

.css-1fhf3k1-control {
  height: 34px;
  max-height: 34px;
  min-height: 34px !important;
  background-color: hsl(0, 0%, 100%) !important;
  border-color: hsl(0, 0%, 80%) !important;
  opacity: 0.35 !important;
  border-radius: 2px !important;
}

.css-107lb6w-singleValue {
  top: calc(50% - 2px) !important;
}

.css-yk16xz-control {
  border-radius: 2px !important;
}

.multi-select .css-yk16xz-control {
  height: auto;
  max-height: unset;
}

.css-1uccc91-singleValue {
  top: calc(50% - 2px) !important;
}

.css-2b097c-container {
  font-size: 14px !important;
  font-weight: normal !important;
}

.p-grid-header-components {
  width: fit-content;
  margin-left: auto;
}

.p-growl-message-error .p-growl-image {
  font-size: 25px !important;
  font-weight: bold !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.topbar-context-dropdown {
  position: relative;
  float: right;
  top: -2px;
  right: 20px;
  width: 200px;
}

.topbar-user-notifications {
  position: relative;
  float: right;
  top: -2px;
  right: 20px;
  width: 50px;
}

.topbar-logo-spinner {
  position: relative;
  float: left;
  top: -10px;
  left: -10px;
  background-color: white;
  width: 60px;
  height: 50px;
}

.p-hamburger-menu {
  float: left;
  margin-left: -10px !important;
}

.myTraffic {
  position: relative;
  top: -10px;
  color: rgb(61, 88, 153);
  background-color: white;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 18px;
  font-size: 25px;
  font-weight: bold;
  float: left;
  height: 50px;
  border-left: 1px solid #b9b9b9;
}

@media only screen and (max-width: 530px) {
  .header-logo {
    display: none !important;
  }

  .topbar-logo-spinner {
    display: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .myTraffic {
    display: none;
  }
}

.red {
  color: red;
}

.warning-label {
  color: red;
  font-size: 12px;
}

#menu-button i {
  font-size: 20px !important;
  font-weight: bold !important;
}

.p-datatable-wrapper {
  overflow: visible !important;
}

.p-growl.p-component.p-growl-topright {
  z-index: 2147483647 !important;
}

.react-tagsinput {
  cursor: pointer;
  border-radius: 4px !important;
}

.react-tagsinput--focused {
  border-width: 1px !important;
  box-shadow: 0 0 0 1px #2684ff !important;
  border-color: #2684ff !important;
}

.react-tagsinput-tag {
  color: hsl(0, 0%, 20%) !important;
  border: none !important;
  background-color: hsl(0, 0%, 80%) !important;
}

.react-tagsinput-remove {
  padding-right: 4px !important;
  margin-left: 4px !important;
  color: hsl(0, 0%, 20%) !important;
}

.react-tagsinput-remove:hover {
  background-color: rgba(226, 106, 106, 0.9) !important;
}

.layout-config-close {
  cursor: pointer;
  position: absolute;
  width: 25px;
  height: 25px;
  text-align: center;
  padding: 0 3px;
  right: 14px;
  top: 10px;
  border: 1px solid #e54a51;
  background-color: #e54a51;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.layout-config-close i {
  color: #ffffff;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
}

@media screen and (max-width: 1024px) {
  .layout-config-close {
    right: 10px;
  }

  .lead-details-tab {
    max-height: 22vh;
    overflow-y: scroll;
    padding: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .lead-details-tab {
    max-height: 9vh;
    overflow-y: scroll;
    padding: 10px;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1600px) {
  .lead-details-tab {
    max-height: 19vh;
    overflow-y: scroll;
    padding: 10px;
  }
}

@media screen and (min-width: 1601px) and (max-width: 1920px) {
  .lead-details-tab {
    max-height: 29vh;
    overflow-y: scroll;
    padding: 10px;
  }
}

.p-button {
  min-width: 80px;
}

body .p-button {
  zoom: 1;
  display: inline-block;
  margin-right: 0.25em !important;
  overflow: visible;
  padding: 0;
  text-decoration: none !important;
}

.p-inputnumber-button {
  min-width: unset;
}

.info-icon {
  color: #0062ff;
  cursor: pointer;
  float: right;
}

.select-dealership-warning {
  color: red;
  margin-top: 6px;
}

.p-dropdown-label.p-inputtext {
  text-align: left;
}

.switch-container {
  display: flex;
  flex-wrap: wrap;
}

.css-b8ldur-Input {
  margin-left: 0 !important;
}

.css-1wa3eu0-placeholder {
  margin-left: -2px !important;
  top: calc(50% - 2px) !important;
}

.react-tagsinput-input {
  width: 100% !important;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.sidebar-title-container {
  margin-top: 25px;
  margin-left: -20px;
  margin-right: -15px;
  border-bottom: 1px solid #afa6a6;
}

#root {
  min-width: 330px;
}

#root .topbar {
  min-width: 330px;
  z-index: 1000;
}

.p-dialog-mask {
  z-index: 2147483647 !important;
}

.uploadPictureContainer {
  width: 320px !important;
  height: 220px !important;
}

.uploadPictureContainerIOffer {
  max-width: 300px; /* Ensures large images scale down */
  max-height: 300px; /* Ensures large images fit properly */
  object-fit: contain; /* Maintains image quality and aspect ratio */
  width: auto; /* Adjusts width proportionally */
  height: auto;
  margin: 10px;
}

.uploadPictureContainerIOffer.dealer-form img {
  max-width: 300px; /* Ensures large images scale down */
  max-height: 300px; /* Ensures large images fit properly */
  object-fit: contain; /* Maintains image quality and aspect ratio */
  width: auto; /* Adjusts width proportionally */
  height: auto; /* Adjusts height proportionally */
}

.uploadPictureContainer img {
  width: 300px !important;
  height: 200px !important;
}

.action-buttons {
  display: flex;
  transform: scale(0.85);
  margin-left: -10%;
}

.action-buttons .p-button {
  min-width: unset;
}

.cursor-pointer {
  cursor: pointer !important;
}

.layout-content-container {
  padding: 10px !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.p-inputtext {
  min-width: 30px !important;
}

.text-center {
  text-align: center;
}

.text-underlined {
  text-decoration: underline;
}

.full-height-width {
  height: 100%;
  width: 100%;
}

.anchor {
  color: #0d8ced;
  cursor: pointer;
}

.p-progressbar-value {
  background: #22b14c !important;
}

.full-width {
  width: 100%;
}

.p-panel-header {
  background: #f5f5f5;
  color: #424242;
  font-weight: 700;
  border: 0 none;
  border-bottom: 1px solid #d8d8d8;
  padding: 0.429em 0.857em;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display: block;
}

.p-panel-header span {
  width: 100%;
  margin-left: 5px;
}

.p-panel .p-panel-header button.p-panel-header-icon {
  display: flex;
  width: 2rem;
}

.p-dropdown-label {
  border: 0 !important;
}

.p-button {
  display: inline-block;
  position: relative;
  padding: 0;
  text-decoration: none !important;
  cursor: pointer;
  text-align: center;
  zoom: 1;
  overflow: visible;
  margin-right: 0.25em !important;
  user-select: none;
  -webkit-user-select: none;
}

body .p-button.p-component .p-button-label {
  padding: 0.385em 0.6em 0.385em 2em !important;
  min-height: 30px;
  align-content: center;
}

#apply-btn .p-button-label {
  padding: 0.385em 0.6em !important;
}

body #export-btn .p-button-label {
  padding: 0.385em 0.6em 0.385em 2em;
}

.pi-download {
  position: absolute !important;
  top: 50% !important;
  margin-top: -0.5em !important;
  height: 1em !important;
  left: 0.5em !important;
}

.p-hidden-accessible.p-dropdown-hidden-select .p-dropdown-label {
  -webkit-border-radius: 0;
}
.p-dropdown-trigger {
  background: white !important;
}

.p-column-filter-add-rule,
.p-column-filter-buttonbar {
  background: white;
  border: 1px solid #cccccc;
}

body .p-button:enabled:hover {
  background-color: #2196f3 !important;
  color: white !important;
}

.p-button-icon.p-button-icon-left {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  height: 1em;
  left: 0.5em;
}

.p-button.p-component.p-button-sm {
  margin-right: 0 !important;
}

.p-datatable table {
  width: 100% !important;
}

.p-datatable-resizable .p-datatable-thead > tr > th,
.p-datatable-resizable .p-datatable-tfoot > tr > td,
.p-datatable-resizable .p-datatable-tbody > tr > td {
  white-space: inherit !important;
}

.p-column-filter-menu {
  margin-left: 0 !important;
}

.p-datatable .p-column-header-content {
  flex-wrap: wrap !important;
  justify-content: center !important;
}

.p-inputswitch {
  width: 3em !important;
  height: 1.75em !important;
}

.p-inputswitch-slider {
  cursor: pointer !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  border-radius: 30px !important;
}

.p-inputswitch-slider:before {
  top: unset !important;
  border-radius: 50% !important;
}

body .p-tabview-nav-content .p-tabview-nav li.p-highlight {
  /* background-color: #39a3f4; */
  color: #ffffff;
}

body .p-tabview-nav-content .p-tabview-nav li.p-highlight a {
  color: #ffffff !important;
  /* padding: 0.571em 0.857em; */
  font-weight: 700;
  transition: box-shadow 0.3s;
  background: #2196f3 !important;
}

body .p-tabview-nav-content .p-tabview-nav li a {
  padding: 0.571em 0.857em;
  color: #424242;
  font-weight: 700;
  transition: box-shadow 0.3s;
}

body .p-tabview-nav-content .p-tabview-nav li a:focus {
  outline: 0 none;
  box-shadow: 0 0 7px 0 #39a3f4;
  -webkit-box-shadow: 0 0 7px 0 #39a3f4;
}

body .p-tabview-nav-content .p-tabview-nav li.p-highlight a i,
.p-tabview-nav-content .p-tabview-nav li a i {
  margin-right: 0.25em;
}

.p-tabview-nav-content .p-tabview-nav li {
  display: block;
  list-style: none;
  float: left;
  margin: 0 0.125em 1px 0 !important;
  padding: 0;
  white-space: nowrap;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  position: static;
  box-sizing: border-box;
  transition: background-color 0.3s;
}

.p-dropdown .p-dropdown-clear-icon {
  position: absolute;
  right: 2em;
  top: 50%;
  font-size: 1em;
  height: 1em;
  margin-top: -0.5em;
}
.p-button-icon-only .p-button-icon {
  top: 50%;
  left: 50%;
  position: absolute;
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
  height: 1em;
}

.p-tabview-nav {
  flex-wrap: wrap;
}

.p-tabview-nav li.p-unselectable-text {
  background-color: #f5f5f5;
}

label i.pi-download {
  position: inherit !important;
  margin-top: 0 !important;
}

input.p-inputtext.p-password-input {
  width: 100%;
}

.p-fieldset-legend.p-unselectable-text {
  padding: 0.5em 1em;
}

#side-banner-source-input legend.p-fieldset-legend.p-unselectable-text,
.p-fieldset.p-component legend.p-fieldset-legend.p-unselectable-text {
  margin-left: 0 !important;
}

.p-overlaypanel-content {
  padding: 0.5em 1em;
}

.p-button.p-component.p-button-secondary.date-range-picker-buttons:enabled:hover {
  border-color: #e7e7e7 !important;
  background-color: #e7e7e7 !important;
}

div div.p-toast-message.p-toast-message-error.p-toast-message-enter-done {
  background-color: #f16863 !important;
  border-color: #f16863 !important;
  color: white !important;
  padding: 0.5em;
  border-radius: 10px;
  z-index: 1202;
  margin: 10px;
}
.p-toast-message-error div.p-toast-message-content {
  background-color: #f16863 !important;
  border-color: #f16863 !important;
  color: white !important;
  border-radius: 10px;
}

div div.p-toast-message.p-toast-message-success.p-toast-message-enter-done {
  background-color: #79ab58 !important;
  border-color: #79ab58 !important;
  color: white !important;
  padding: 1em 0.5em;
  border-radius: 10px;
  z-index: 1202;
  margin: 10px;
}
.p-toast-message-success div.p-toast-message-content {
  background-color: #79ab58 !important;
  border-color: #79ab58 !important;
  color: white !important;
  border-radius: 10px;
}

div div.p-toast-message.p-toast-message-info.p-toast-message-enter-done {
  background-color: #adaef2 !important;
  border-color: #adaef2 !important;
  color: white !important;
  padding: 1em 0.5em;
  border-radius: 10px;
  z-index: 1202;
  margin: 10px;
}
.p-toast-message-info div.p-toast-message-content {
  background-color: #adaef2 !important;
  border-color: #adaef2 !important;
  color: white !important;
  border-radius: 10px;
}

div div.p-toast-message.p-toast-message-warn.p-toast-message-enter-done {
  background-color: #ecb460 !important;
  border-color: #ecb460 !important;
  color: white !important;
  padding: 1em 0.5em;
  border-radius: 10px;
  z-index: 1202;
  margin: 10px;
}
.p-toast-message-warn div.p-toast-message-content {
  background-color: #ecb460 !important;
  border-color: #ecb460 !important;
  color: white !important;
  border-radius: 10px;
}

.p-toast-icon-close.p-link {
  color: white;
}

body .p-button.p-component.no-icon-buttons .p-button-label {
  padding: 0.385em 0.6em 0.385em 0.6em !important;
}

.role-category {
  padding: 10px !important;
}

.p-multiselect-header .p-multiselect-filter-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 65%;
}

.p-multiselect-header .p-multiselect-close {
  position: absolute;
  right: 0.375em;
  top: 0.375em;
  display: block;
  border: 0;
}

body .p-multiselect-panel .p-multiselect-header {
  display: block !important;
}

.p-button.p-component.p-button-outlined.p-button-sm .p-button-label {
  padding: 0.385em 0.6em 0.385em 0.6em;
}

.p-dialog-default .p-dialog-header {
  background: #f5f5f5;
  color: #424242;
  border: 1px solid #d8d8d8;
  font-weight: 700;
  -moz-border-radius-topleft: 2px;
  -webkit-border-top-left-radius: 2px;
  border-top-left-radius: 2px;
  -moz-border-radius-topright: 2px;
  -webkit-border-top-right-radius: 2px;
  border-top-right-radius: 2px;
  padding: 1em;
  border: 0 none;
}

div#enterprise-user-dealerships .p-datatable-wrapper {
  height: calc(100% - 90px);
  max-height: 100% !important;
}

div.p-datatable-wrapper .p-datatable-tbody {
  max-height: 570px;
  overflow: auto;
  position: relative;
}

.public-inputswitch {
  height: 12px !important;
}

.p-button {
  display: inline-flex !important;
}

.editable-field {
  overflow: inherit !important;
}

.edit-offer-name {
  min-width: 100px;
  z-index: 1000;
  position: relative;
}

.p-column-filter-overlay.p-component.p-fluid.p-ripple-disabled.p-connected-overlay-enter-done {
  transform: scale(1.2);
  background: white;
  border: 1px solid #d8d8d8;
}

.p-column-filter-menu-button {
  margin-left: 5px !important;
}

.p-column-filter-menu-button,
.p-column-filter-clear-button {
  display: none !important;
}

.p-dropdown-panel.p-component.p-connected-overlay-enter-done {
  z-index: 2147483648 !important;
}

div.sidebar-selected-session .p-sidebar-content,
.p-sidebar.p-component.p-sidebar-enter-done .p-sidebar-content {
  overflow-y: auto;
}

.p-colorpicker-panel.p-colorpicker-overlay-panel.p-connected-overlay-enter-done {
  z-index: 2147483648 !important;
}

body .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover,
body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
body .p-paginator .p-paginator-pages .p-paginator-page {
  text-align: center;
}
body .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 10px;
}

.p-slider-handle.p-slider-handle-start,
.p-slider-handle.p-slider-handle-end,
.p-slider-handle {
  border: 7px solid #2198f3;
  border-radius: 50%;
  top: -7px !important;
}

.p-tooltip.p-component.p-tooltip-top.yellow-tooltip.p-tooltip-active {
  color: white;
  border-radius: 5%;
  padding: 5px;
}

.p-tooltip.p-component.p-tooltip-top.yellow-tooltip.p-tooltip-active
  .p-tooltip-text {
  background-color: #39a3f4;
  border: 1px solid #39a3f4;
  color: white;
  border-radius: 5%;
  padding: 5px;
  font-size: 1rem;
}

.enterprise-select-users {
  z-index: 10 !important;
}

.vdp-checkboxes {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  justify-content: end;
  margin-bottom: 75px;
}

.horizontal-bar-dropdowns {
  display: flex;
  flex-direction: row;
  justify-content: start;
  column-gap: 15px;
  flex-wrap: wrap;
}

.bar-dropdown {
  min-width: 100px;
  margin: 5px 0;
}

div.p-datepicker.p-component.p-connected-overlay-enter-done,
div.p-datepicker.p-component {
  z-index: 2147483647 !important;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border-radius: 2px;
  vertical-align: middle;
  color: #ffffff;
  background-color: #39a3f4;
  border: 1px solid transparent;
  font-size: 14px;
  padding: 0.385em 0.6em 0.385em 0.6em;
  display: inline-block;
  cursor: pointer;
}

.my-campaigns div div table thead.p-datatable-thead,
.dealerships div div table thead.p-datatable-thead,
.offers div div table thead.p-datatable-thead,
.dealership-users div div table thead.p-datatable-thead,
.chat-greeting div div table thead.p-datatable-thead,
.chat-greeting-ten div div table thead.p-datatable-thead {
  padding-right: 10px;
  border-right: 1px solid #d8d8d8;
  background-color: #f5f5f5;
}

/* .dealerships div div table tbody.p-datatable-tbody,
.chat-greeting div div table tbody.p-datatable-tbody,
.chat-greeting-ten div div table tbody.p-datatable-tbody,
.offers div div table tbody.p-datatable-tbody,
.my-campaigns div div table tbody.p-datatable-tbody {
  padding-right: 10px;
} */

.dealerships div div table tbody.p-datatable-tbody tr td,
.dealerships-ten div div table tbody.p-datatable-tbody tr td,
.dealerships div div table thead.p-datatable-thead tr th,
.dealerships-ten div div table thead.p-datatable-thead tr th {
  border: none;
  border-left: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  overflow: hidden;
}

.dealerships div div table tbody.p-datatable-tbody,
.dealerships-ten div div table tbody.p-datatable-tbody,
.dealerships div div table tbody.p-datatable-tbody tr,
.dealerships div div table thead.p-datatable-thead,
.dealerships-ten div div table thead.p-datatable-thead,
.dealerships div div table thead.p-datatable-thead tr {
  border: none;
  border-right: 1px solid #d8d8d8;
}

.offers div div table tbody.p-datatable-tbody tr td,
.offers div div table thead.p-datatable-thead tr th {
  border: none;
  border-left: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  overflow: hidden;
}

.offers div div table tbody.p-datatable-tbody,
.offers div div table tbody.p-datatable-tbody tr,
.offers div div table thead.p-datatable-thead,
.offers div div table thead.p-datatable-thead tr {
  border: none !important;
  border-right: 1px solid #d8d8d8;
}

.my-campaigns div div table tbody.p-datatable-tbody tr td,
.my-campaigns div div table thead.p-datatable-thead tr th {
  border: none;
  border-left: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  overflow: hidden;
}

.my-campaigns div div table tbody.p-datatable-tbody,
.my-campaigns div div table tbody.p-datatable-tbody tr,
.my-campaigns div div table thead.p-datatable-thead,
.my-campaigns div div table thead.p-datatable-thead tr {
  border: none;
  border-right: 1px solid #d8d8d8;
}

.dealership-users div div table tbody.p-datatable-tbody tr td,
.dealership-users-ten div div table tbody.p-datatable-tbody tr td,
.dealership-users div div table thead.p-datatable-thead tr th,
.dealership-users-ten div div table thead.p-datatable-thead tr th {
  border: none;
  border-left: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
}

.dealership-users div div table tbody.p-datatable-tbody,
.dealership-users-ten div div table tbody.p-datatable-tbody,
.dealership-users div div table tbody.p-datatable-tbody tr,
.dealership-users div div table thead.p-datatable-thead,
.dealership-users-ten div div table thead.p-datatable-thead,
.dealership-users div div table thead.p-datatable-thead tr {
  border: none;
  border-right: 1px solid #d8d8d8;
}

div.dealership-users div div.p-datatable-wrapper .p-datatable-tbody,
div.dealership-users-ten div div.p-datatable-wrapper .p-datatable-tbody {
  max-height: 450px !important;
}

.chat-greeting div div table tbody.p-datatable-tbody tr td,
.chat-greeting-ten div div table tbody.p-datatable-tbody tr td,
.chat-greeting div div table thead.p-datatable-thead tr th,
.chat-greeting-ten div div table thead.p-datatable-thead tr th {
  border: none;
  border-left: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  overflow: hidden;
}

.chat-greeting div div table tbody.p-datatable-tbody,
.chat-greeting-ten div div table tbody.p-datatable-tbody,
.chat-greeting div div table tbody.p-datatable-tbody tr,
.chat-greeting-ten div div table tbody.p-datatable-tbody tr,
.chat-greeting div div table thead.p-datatable-thead,
.chat-greeting-ten div div table thead.p-datatable-thead,
.chat-greeting div div table thead.p-datatable-thead tr,
.chat-greeting-ten div div table thead.p-datatable-thead tr {
  border: none;
  border-right: 1px solid #d8d8d8;
}

div.chat-greeting div div.p-datatable-wrapper .p-datatable-tbody,
div.chat-greeting-ten div div.p-datatable-wrapper .p-datatable-tbody {
  max-height: 500px !important;
}

div#event_alerts
  div
  div
  div.chat-greeting-ten
  div
  div
  table
  thead.p-datatable-thead,
div#event_alerts
  div
  div
  div.chat-greeting
  div
  div
  table
  thead.p-datatable-thead {
  border-top: 1px solid #d8d8d8 !important;
}

div#event_markers
  div
  div
  div.chat-greeting-ten
  div
  div
  table
  thead.p-datatable-thead,
div#event_markers
  div
  div
  div.chat-greeting
  div
  div
  table
  thead.p-datatable-thead {
  border-top: 1px solid #d8d8d8 !important;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  align-items: center !important;
  cursor: pointer !important;
  display: flex !important;
  overflow: hidden !important;
  position: relative !important;
  text-decoration: none !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  padding: 0.571em 0.857em !important;
  background-color: #f5f5f5 !important;
  border: none !important;
  color: black !important;
  background: none !important;
}

div.offers div div table tbody.p-datatable-tbody,
div.offers div div table tbody.p-datatable-tbody tr,
div.offers div div table thead.p-datatable-thead,
div.offers div div table thead.p-datatable-thead tr {
  border: none !important;
}

div#offer-preview-panel div div.p-panel-content,
div#templates-form-view div div div div.p-panel-content {
  padding: 0.857em !important;
  border: none !important;
  background: none !important;
}

body .p-paginator,
span.p-paginator-current,
span.p-column-title,
span.p-sortable-column-icon.pi.pi-fw.pi-sort-alt,
body .p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon,
th.p-sortable-column.p-resizable-column.p-highlight:hover
  div
  span.p-column-title {
  color: black !important;
}

body .p-datatable .p-sortable-column.p-highlight:hover {
  color: black !important;
  background-color: #b2dbfb !important;
}

th.p-sortable-column.p-resizable-column.p-highlight div span.p-column-title {
  color: white !important;
}

div.p-toast-message-content {
  padding: 0 !important;
}

div.p-radiobutton-box.p-highlight {
  background: white !important;
}

div.p-radiobutton-icon {
  margin-top: 0em !important;
}

div#offer-preview-panel div div.p-panel-content {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  justify-content: center;
}

#offer-restrictions-panel.p-panel-header {
  display: flex !important;
}

.pi-upload:before,
div#reports-view .pi-send:before,
div#support-portal-view
  div#outside-element
  span.p-button-icon.p-c.p-button-icon-left.pi.pi-plus::before {
  position: absolute;
  left: 0;
}

body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .pi,
body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight span {
  color: black;
}

div.p-fileupload
  .p-button-icon.p-button-icon-left.p-clickable.pi.pi-fw.pi-plus {
  margin-top: -0.5em;
  margin-left: -0.5em;
}
