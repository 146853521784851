.rts-template-item-campaigns {
  width: 200px;
  height: 180px;
  border: 1px solid #f1f1f5;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 0 1px, rgba(0, 0, 0, 0.15) 0 8px 16px;
}

#rts-template-chat-permission-container {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  margin: auto;
  position: relative;
  animation-name: rtsChatPermissionLoad;
  animation-duration: 0.5s;
  display: block;
  pointer-events: none;
}

#rts-template-chat-permission-close-svg {
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: white;
  border-radius: 20px;
}

#rts-template-chat-permission-header {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Montserrat, serif;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: normal;
  text-align: center;
  color: white;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin: auto;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center; /* Vertically centers the content */
  justify-content: center;
}

#rts-template-chat-permission-question-container {
  width: 100%;
  height: 55%;
  background-color: white;
  position: absolute;
  bottom: 0;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#rts-template-chat-permission-question-header {
  font-family: Montserrat, serif;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: center;
  color: #1a1a1a;
  position: absolute;
  top: 30px;
  margin: auto;
  white-space: pre-wrap;
}

#rts-template-chat-permission-question-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 50%;
  height: 25px;
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.08);
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 30px;
  cursor: pointer;
}

#rts-template-chat-permission-question-yes {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100%;
  height: 26px;
  font-family: Montserrat, serif;
  font-size: 8px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: white;
  position: absolute;
  top: 9px;
  text-align: center;
}

#rts-template-chat-permission-question-arrow {
  width: 10px;
  height: 10px;
  object-fit: contain;
  position: absolute;
  top: -2px !important;
}

@media screen and (max-width: 40em) {
  #chat-greetings .p-datatable-row td {
    width: 225px !important;
  }

  .p-datatable-scrollable-colgroup {
    display: none;
  }
}
