.date-range-picker-button-bar {
  text-align: end;
}

.date-range-picker-container {
  position: relative;
}

.date-range-picker-input {
  height: 34px;
}

.date-range-picker-overlay {
  left: auto !important;
  top: auto !important;
}

.date-range-widget {
  z-index: 2147483648 !important;
}