#global-settings-view {
}

#global-settings-view .description {
    font-size: 12px;
}
.status-disabled {
    padding: 5px;
    color: white;
    background-color: #EF5350;
    border-radius: 4px;
    min-width: 95px;
}

.status-enabled {
    padding: 5px;
    color: white;
    background-color: #6ebc3b;
    border-radius: 4px;
    min-width: 95px;
}