#my-campaigns-view {
}

/*my-campaigns Form*/
#my-campaigns-form-view {
  height: 100%;
}

#my-campaigns-form-view .label-container .red {
  color: red;
}

#my-campaigns-form {
  width: calc(100% - 495px);
  margin-right: 15px;
}

#my-campaigns-preview {
  margin-left: auto;
  margin-right: auto;
  width: min-content;
  min-width: 450px;
  height: min-content;
  background-color: #FFFFFF;
  border-radius: 45px;
  position: relative;
}

@media only screen and (max-width: 900px) {
  #my-campaigns-form {
    width: 100%;
  }

  #my-campaigns-preview {
    margin-top: 10px;
  }
}

.input-switch-label {
  margin-right: 5px;
  margin-top: -3px;
}

#my-campaigns-form-view .p-inputnumber-input {
  min-width: 0;
  width: 100%;
}

#my-campaigns-form-view .eng-score-input {
  margin-right: -3px;
}

#my-campaigns-form-view .eng-score-input input {
  border: unset !important;
  border-left: 1px solid #cccccc !important;
}

#expirable-panel .p-toggleable-content {
  display: none;
}