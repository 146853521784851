
#dealership-form .row {
    margin-bottom: 25px;
}

#dealership-form .col-2 {
    width: calc(50% - 5px);
}

#dealership-form .col-1 {
    width: 100%;
}

#dealership-form .warning-label {
    color: red;
    font-size: 10px;
}

#dealership-form .red {
    color: red;
}

#dealership-form .p-dropdown-panel .p-dropdown-filter-container {
    width: 100%;
}

#dealership-form .p-dropdown .p-dropdown-panel {
    top: 33px !important;
    left: 0 !important;
}

#dealership-form .panel-title {
    color: gray;
    font-size: 1.3rem;
}

#dealership-form .input-label {
    color: gray;
}

#contact-form .input-label {
    float: left;
}

#dealership-form .label-container {
    margin-top: 5px;
}

#dealership-form .p-label-col {
    padding-bottom: 0;
}

#dealership-form .p-grid {
    margin-top: 5px;
}

#dealership-form .react-tagsinput-tag {
    color: white;
    background-color: #5bc0de;
    border-color: #5bc0de;
}

#dealership-form .react-tagsinput-tag a {
    color: white;
}

#dealership-form .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: #5bc0de;
}

#dealership-form .react-tagsinput-input {
    width: 150px;
}

#dealership-form .react-tagsinput--focused {
    border-color: #5bc0de;
}

.contact-form-switch-container {
    margin-left: 5px;
    padding-top: 3px;
}
.service-text-area{
    width: 100%;
    max-width: 100%;
    min-width: 100% !important;
    height: auto;
    min-height: 80px;
}

.ioffer-d-flex {
    display: flex;
}


@media (min-width: 992px) {
    .customizable-banner {
        padding: 1rem !important;
    }
}

@media screen and (min-width: 992px) {
    .customizable-banner {
        width: 41.6667%;
        flex: 0 0 auto;
    }
}