body .p-datatable .p-datatable-tfoot > tr > td {
    padding: 0.429em 0.857em;
    border: 1px solid #d8d8d8;
    background-color: #636363; /* Light gray background */
    color: rgb(241, 241, 241); /* Black text */
    font-weight: bold;
    text-align: center;
}

.offers div div table tbody.p-datatable-tbody, 
.offers div div table tbody.p-datatable-tbody tr, 
.offers div div table thead.p-datatable-thead, 
.offers div div table thead.p-datatable-thead tr {
  border: 1px solid #d8d8d8 !important;
  border-right: 1px solid #d8d8d8;
}