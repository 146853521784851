.visitor-details .row {
  display: flex;
}

.visitor-details .sidebar-img {
  float: left;
  width: 18px;
}

.visitor-details .sidebar-text {
  margin-left: 15px;
  float: left;
  word-wrap: break-word;
  width: calc(100% - 30px);
}

.visitor-details .visitor-history {
  padding: 0 !important;
}