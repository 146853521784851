.p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 1rem;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
    margin-right: 0.5rem;
}

.p-button.p-button-icon-only {
    width: 1rem;
    padding: 0.75rem 0;
    font-size: 1rem;
}
.p-button.p-button-outlined {
    background-color: #06b6d4;
    color: #ffffff;
    border: 1px solid;
    width: 2.5rem !important;
    height: 2.5rem !important;
}
body .p-fileupload-choose:not(.p-disabled):hover, body .p-fileupload-choose.p-focus {
    background-color: #ffffff !important;
    color: #06b6d4 !important;
    border: 1px solid #06b6d4 !important;
}
body .p-button-success {
    color: #ffffff !important;
    background-color: #22c55e !important;
    border: 1px solid #ffffff !important;
}
body .p-button-success:enabled:hover {
    background-color: #ffffff !important;
    color: #22c55e !important;
    border: 1px solid #22c55e !important;
}
body .p-button-danger {
    color: #ffffff !important;
    background-color: #ef4444 !important;
    border: 1px solid #ffffff !important;
}
body .p-button-danger:enabled:hover {
    background-color: #ffffff !important;
    color: #ef4444 !important;
    border: 1px solid #ef4444 !important;
}

.p-button-icon-only {
    justify-content: center;
}

.p-button {
    margin: 0;
    display: inline-flex;
    cursor: pointer;
    user-select: none;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    overflow: hidden;
    position: relative;
    min-width: 0;
}
.p-button-icon-only .p-button-icon {
    top: 50%;
    left: 50%;
    position: absolute;
    margin-top: -0.5em;
    margin-left: -0.5em;
    width: 1em;
    height: 1em;
    font-size: 0.8rem;
}
.custom-choose-btn, .custom-upload-btn, .custom-cancel-btn {
    margin-top: 2px;
}