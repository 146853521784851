#my-traffic-view {
}

#my-traffic-view .icon {
    margin-right: 2px;
    cursor: pointer;
    float: left;
    width: 20px;
    border-radius: 45px;
    padding: 1px;
}

#my-traffic-view .p-tabview .p-tabview-nav {
    margin-bottom: 0 !important;
}

.stats-tabview-container {
    padding: 5px !important;
}

.pi-dollar {
    font-weight: bold !important;
}