.input-label {
    font-weight: bold;
}

#dynamic-offer-container {
    height: 283px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    position: relative;
}

#dynamic-offer-container #text-wrapper {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 15px;
}

#dynamic-offer-container .text-container {
    text-align: center;
}

#dynamic-offer-container .text {
    color: #FFFFFF;
    font-size: 22px;
    font-weight: bold;
}

#eng-lead-history .p-datatable {
    height: 300px;
}