.chatbot-badge {
  margin-left: 5px;
  opacity: 0.7;
}

#active-chats .css-yk16xz-control {
  max-height: unset;
  height: auto;
}

#active-chats .css-1pahdxg-control {
  max-height: unset;
  height: auto;
}