.sidebar-item {
  width: 100%;
  margin-bottom: 5px;
}

#dealership-campaign .icon {
  float: unset !important;
  font-size: 14px !important;
}

.css-2b097c-container {
  width: 100%;
}

#report-modal .p-dropdown-panel {
  bottom: 34px;
  top: unset !important;
}

#period-chatbots-sidebar .p-inputtext {
  width: 100%;
}

.chatbot-stats-value {
  font-weight: bold;
  margin-left: 5px;
  float: right;
}

.stats-bubbles {
  border: 2px solid #3dd598;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.stats-bubbles .bubble-text {
  left: unset !important;
}

.legend-item-stats {
  padding: 5px;
  width: 100px;
}

.click-ins-rep-title {
  display: flex;
  justify-content: space-around;
  font-size: 1.3rem;
  margin: 10px;
  color: black;
  padding: 10px;
}

.click-ins-rep-title p {
  margin: 0;
}

.click-ins-rep-body {
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  margin: 10px;
  padding: 10px;
  background-color: black;
  color: white;
}

.click-ins-rep-body p,
.damage-properties-div p {
  margin-bottom: 0;
}

.click-ins-customer-body,
.car-info-click-ins {
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  margin: 10px;
  padding: 10px;
  border-bottom: 1px solid gray;
}

.inspection-location {
  display: flex;
  padding: 10px;
  margin: 5px;
}

.inspection-location div {
  flex-grow: 4;
}
.inspection-location p {
  flex-grow: 1;
}

.inspection-img-1 {
  display: flex;
}

.inspection-images {
  padding: 10px;
  margin: 5px;
}

.vehicle-photos-header {
  display: flex;
  padding: 10px;
  margin: 5px;
  font-size: 1.3rem;
  column-gap: 10px;
}

.vehicle-photos {
  border-bottom: 1px solid gray;
}

.vehicle-photos-body {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
}

.damage-element {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  margin: 10px;
}

.damage-recognition-element {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20%;
  row-gap: 25px;
  margin: 10px 30px;
}

.damage-image-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.damage-properties-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.damage-properties-div p {
  width: max-content;
}

.no-click-events {
  pointer-events: none;
  user-select: text;
  -webkit-user-select: text; /* For older Safari versions */
  -moz-user-select: text; /* For older Firefox versions */
  -ms-user-select: text; /* For older IE/Edge versions */
}

_::-webkit-full-page-media,
_:future,
:root .no-click-events {
  pointer-events: all !important;
  user-select: text;
  -webkit-user-select: text;
}
