#roles-management {
    height: 100%;
}

#roles-management-grid {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
}

#role-list-menu {
    min-height: 100%;
    background-color: white;
    padding: 10px;
    max-height: 100%;
    overflow-y: auto;
}

#role-management-container {
    max-height: 100%;
    overflow-y: auto;
}

#filter-and-roles-container {
    width: 100%;
    margin-top: 10px;
}

#roles-container {
    width: 100%;
    margin-top: 10px;
}

.role-list-element {
    margin-bottom: 10px;
}

.role-list-label {
    cursor: pointer;
}

#role-list-menu #title {
    color: black;
    font-weight: bold;
    font-size: 18px;
}
#new-role-container{
    margin-top: 10px;
}
